import React from "react";
import "./AdminAccountList.css";

const AdminExpenseList = ({
  name,
  category,
  amount,
  description,
  date,
  index,
}) => {
  return (
    <div className="AdminAccounts-table-row" key={index}>
      <div className="AdminAccountList-staffName">
        <p>Name:</p>
        <p>{name}</p>
      </div>
      <div className="AdminAccountList-Bill">
        <p>Date</p>
        <p>{date}</p>
      </div>
      <div className="AdminAccountList-Category">
        <p>Category:</p>
        <p>{category}</p>
      </div>
      <div className="AdminAccountList-Bill">
        <p>Description </p>
        <p>{description}</p>
      </div>

      <div className="">
        <p>Amount</p>
        <p>{amount}</p>
      </div>
    </div>
  );
};

export default AdminExpenseList;

import React, { useState } from "react";
import "./AdminAccounts.css";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminAccountList from "./AdminAccountList/AdminAccountList";
import AdminHeader from "../AdminHeader/AdminHeader";
import { useGetPaymentsQuery } from "../../../hooks/payment";
import { useGetStaffsQuery } from "../../../hooks/staff";
import { formatDistanceToNow } from "date-fns";
import { CgSpinner } from "react-icons/cg";

const AdminAccounts = () => {
  const { data: AdminAccountitems, isPending: isFetchingPayments } =
    useGetPaymentsQuery();
  const { data: staff, isPending: isFetchingStaffs } = useGetStaffsQuery();

  const [adminProfileShow, setAdminProfileShow] = useState(false);
  const [dateRange, setDateRange] = useState(""); // State for date range filter

  const [categoryFilter, setCategoryFilter] = useState(""); // State for category filter

  // Reset filters
  const handleResetFilters = () => {
    setCategoryFilter("");
    setDateRange("");
  };

  const filteredItems = AdminAccountitems?.filter((item) => {
    const matchesCategory = categoryFilter
      ? item.category.toLowerCase().includes(categoryFilter.toLowerCase())
      : true;

    const itemDate = new Date(item.createdAt); // Convert item date to a Date object
    const now = new Date();

    let matchesDate = true; // Default is true if no range is selected

    if (dateRange) {
      switch (dateRange) {
        case "Today":
          matchesDate = itemDate.toDateString() === now.toDateString(); // Same day
          break;

        case "Week":
          const startOfWeek = new Date(
            now.setDate(now.getDate() - now.getDay())
          );
          matchesDate = itemDate >= startOfWeek && itemDate <= new Date();
          break;

        case "Month":
          const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          matchesDate = itemDate >= startOfMonth && itemDate <= new Date();
          break;

        case "Year":
          const startOfYear = new Date(now.getFullYear(), 0, 1);
          matchesDate = itemDate >= startOfYear && itemDate <= new Date();
          break;

        default:
          matchesDate = true; // No range filtering
      }
    }

    return matchesCategory && matchesDate;
  });

  // Calculate totals
  const totalBill =
    filteredItems?.reduce((sum, item) => sum + item.bill, 0) || 0;
  const totalPaid =
    filteredItems?.reduce((sum, item) => sum + item.paid, 0) || 0;
  const totalBalance = totalBill - totalPaid;

  return (
    <div className="AdminAccounts">
      <div className="AdminAccounts-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="AdminAccounts-space">
        <div className="AdminAccounts-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="AdminAccounts-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="AdminAccounts-space-body">
          <div className="AdminAccounts-title-board">
            <h2>Income:</h2>
            {isFetchingPayments || isFetchingStaffs ? (
              <CgSpinner
                className="animate-spin"
                style={{ color: "red" }}
                size={40}
              />
            ) : (
              ""
            )}
            <div className="filter-controls">
              {/* Filters */}
              <select
                name="date"
                id=""
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
                style={{ color: "black", padding: 7 }}
              >
                <option value="">Date</option>
                <option value="Today">Today</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
                <option value="Year">Year</option>
              </select>

              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                name="Category"
                id=""
                style={{ color: "black", padding: 7 }}
              >
                <option value="Today">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>

              <button onClick={handleResetFilters} className="reset-button">
                Reset
              </button>
            </div>
          </div>
          <section className="AdminAccounts-board">
            <div className="AdminAccounts-center-board">
              <div className="AdminAccounts-table-head">
                <p>Staff Name</p>
                <p>Category</p>
                <p>Bill</p>
                <p>Paid</p>
                <p>Balance</p>
                <p>Date</p>
              </div>
              <div className="AdminAccounts-table-body">
                {isFetchingPayments || isFetchingStaffs ? (
                  <span style={{ color: "black" }}>loading...</span>
                ) : (
                  filteredItems?.map((item, index) => {
                    const staffMember = staff?.find(
                      (s) => s.staffUniqueId === item.collectedBy
                    );

                    const balance = item.bill - item.paid;
                    const formattedDate = formatDistanceToNow(
                      new Date(item.createdAt),
                      {
                        addSuffix: true,
                      }
                    );

                    return (
                      <AdminAccountList
                        key={index}
                        name={staffMember?.fullName || "Unknown"}
                        category={item.category}
                        bill={item.bill}
                        paid={item.paid}
                        balance={`${balance}.00`}
                        date={formattedDate}
                        index={index}
                      />
                    );
                  })
                )}
              </div>
              <div className="AdminAccounts-totals">
                <h5>Bill: {totalBill}</h5>
                <h5>Paid: {totalPaid}</h5>
                <h5>Balance: {totalBalance}</h5>
              </div>
            </div>
          </section>
          {/* Totals Section */}
        </div>
      </div>
    </div>
  );
};

export default AdminAccounts;

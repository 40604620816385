import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMakeExpensesMutation } from "../../../hooks/payment";
import { Store } from "../../../Store";
import GuestReservationHeader from "../../GuestReservationHeader/GuestReservationHeader";
import "./CashierExpenses.css";

const CashierExpenses = () => {
  const navigate = useNavigate();
  const {
    state: { userInfo },
  } = useContext(Store);

  const [category, setCategory] = useState("");

  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");

  const collectedBy = userInfo.uniqueId;

  const { mutateAsync: saveExpenses } = useMakeExpensesMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const billNumber = Number(amount);

    try {
      const paymentData = {
        staff: userInfo._id,
        collectedBy,
        category,
        amount: billNumber,
        description,
      };

      await saveExpenses(paymentData);
      toast.success("Expenses updated successfully");
      navigate(`/Expenses`);
    } catch (error) {
      console.error("Error updating payment:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <div className="CashierExpenses">
      <div className="CashierExpenses-hue">
        <div className="CashierExpenses-head">
          <GuestReservationHeader />
        </div>
        <div className="CashierExpenses-body">
          <p>Expenses</p>
          <form
            action=""
            onSubmit={handleSubmit}
            className="CashierExpenses-form"
          >
            <div className="CashierExpenses-title">
              <p>Collected by: {userInfo ? userInfo.fullName : ""}</p>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                name="Category"
                id=""
              >
                <option value="Today">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <textarea
              name=""
              id=""
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              cols={50}
              rows={4}
              placeholder="Description"
            ></textarea>
            <label className="CashierExpenses-label">
              Amount:
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="text"
                placeholder=""
              />
            </label>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CashierExpenses;

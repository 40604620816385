import React, { useState } from "react";
import "./AdminDashboard.css";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminHeader from "../AdminHeader/AdminHeader";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  return (
    <div className="AdminDashboard">
      <div className="AdminDashboard-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="AdminDashboard-space">
        <div className="AdminDashboard-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <section className="AdminDashboard-space-board">
          <div className="AdminDashboard-board">
            <p>Administrative</p>
            <div className="AdminDashboard-board-btnHolder">
              <button>
                <Link to="/CreateStaff">Staff</Link>{" "}
              </button>
              <button>
                <Link to="/AdminAccounts">Account</Link>{" "}
              </button>
              <button>
                <Link to="/ManageGuest">Guest</Link>
              </button>
              <button>
                <Link to="/Expenses">Expenses</Link>
              </button>
            </div>
          </div>
          <div className="AdminDashboard-board">
            <p>Settings</p>
            <div className="AdminDashboard-board-btnHolder">
              <button>Prices</button>
              <button>Emails</button>
              <button>Categories</button>
              <button>Position</button>
              <button>Salary</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminDashboard;

import React, { useContext, useEffect, useState } from "react";
import { Store } from "../Store";
import StaffDashboard from "./Staff-Receptionist/StaffDashboard/StaffDashboard";
import CashierDashboard from "./Cashier/CashierDashboard/CashierDashboard";
import BartenderDashboard from "./Staff-BartenderAndServers/BartenderDashboard/BartenderDashboard";
import AdminDashboard from "./Master-Admin/AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    state: { userInfo },
  } = useContext(Store);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userInfo) {
      navigate("/SignIn");
    } else {
      setLoading(false);
    }
  }, [userInfo, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Placeholder while determining state
  }

  return (
    <>
      {userInfo.position === "Receptionist" ? (
        <StaffDashboard />
      ) : userInfo.position === "Admin" ? (
        <AdminDashboard />
      ) : userInfo.position === "Bartender" ||
        userInfo.position === "Waiter" ? (
        <BartenderDashboard />
      ) : userInfo.position === "Cashier" ? (
        <CashierDashboard />
      ) : (
        <div>Unauthorized User</div>
      )}
    </>
  );
};

export default Dashboard;

import React, { useState } from "react";
import "./BartenderDashboard.css";
import BartenderProfile from "../BartenderProfile/BartenderProfile";
import BartenderHeader from "../BartenderHeader/BartenderHeader";
import { Link } from "react-router-dom";

const BartenderDashboard = () => {
  const [barProfileShow, setBarProfileShow] = useState(false);
  return (
    <div className="BartenderDashboard">
      <div className="BartenderDashboard-profile">
        <BartenderProfile barProfileShow={true} />
      </div>
      <div className="BartenderDashboard-space">
        <div className="BartenderDashboard-mobile-header">
          <BartenderProfile
            barProfileShow={barProfileShow}
            setBarProfileShow={setBarProfileShow}
          />
          <BartenderHeader setBarProfileShow={setBarProfileShow} />
        </div>
        <div className="BartenderDashboard-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="BartenderDashboard-space-body">
          <div className="BartenderDashboard-board-up">
            <p>Activities</p>
            <div className="BartenderDashboard-board-up-container">
              {/* <button><Link to='/OpenSales'>Open Sales</Link> </button> */}
              <button>
                <Link to="/ManageGuest">Guest</Link>{" "}
              </button>
              <button>
                <Link to="/RegisterGuests">Register Guest</Link>{" "}
              </button>
              {/* <button></button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BartenderDashboard;

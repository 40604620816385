import { formatDistanceToNow } from "date-fns";
import React, { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { useGetExpensesQuery } from "../../../hooks/payment";
import { useGetStaffsQuery } from "../../../hooks/staff";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminExpenseList from "./AdminAccountList/AdminExpense";
import "./AdminAccounts.css";

const Expenses = () => {
  const { data: AdminAccountitems, isPending: isFetchingPayments } =
    useGetExpensesQuery();
  const { data: staff, isPending: isFetchingStaffs } = useGetStaffsQuery();

  const [adminProfileShow, setAdminProfileShow] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState(""); // State for category filter
  const [dateRange, setDateRange] = useState(""); // Predefined date range (week, month, year)

  const handleResetFilters = () => {
    setCategoryFilter("");
    setDateRange("");
  };

  const calculateRangeDates = () => {
    const now = new Date();
    let start;
    let end = now;

    switch (dateRange) {
      case "week":
        start = new Date(now);
        start.setDate(now.getDate() - 7); // Last 7 days
        break;
      case "month":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1); // Start of last month
        end = new Date(now.getFullYear(), now.getMonth(), 0); // End of last month
        break;
      case "year":
        start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()); // Last year from today
        break;
      default:
        start = null;
    }

    return { start, end };
  };

  const filteredItems = AdminAccountitems?.filter((item) => {
    const matchesCategory = categoryFilter
      ? item.category.toLowerCase().includes(categoryFilter.toLowerCase())
      : true;

    const itemDate = new Date(item.createdAt);
    let matchesDate = true;

    if (dateRange) {
      const { start, end } = calculateRangeDates();
      if (start && end) {
        matchesDate = itemDate >= start && itemDate <= end;
      }
    }

    return matchesCategory && matchesDate;
  });

  const totalAmount =
    filteredItems?.reduce((sum, item) => sum + item.amount, 0) || 0;

  return (
    <div className="AdminAccounts">
      <div className="AdminAccounts-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="AdminAccounts-space">
        <div className="AdminAccounts-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="AdminAccounts-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="AdminAccounts-space-body">
          <div className="AdminAccounts-title-board">
            <h2>Expenses:</h2>
            {isFetchingPayments || isFetchingStaffs ? (
              <CgSpinner
                className="animate-spin"
                style={{ color: "red" }}
                size={40}
              />
            ) : null}
            <div className="filter-controls">
              <select
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
                style={{ color: "black", padding: 7 }}
              >
                <option value="">Select Range</option>
                <option value="week">Last 7 Days</option>
                <option value="month">Last Month</option>
                <option value="year">Last Year</option>
              </select>
              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                style={{ color: "black", padding: 7 }}
              >
                <option value="">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>
              <button onClick={handleResetFilters} className="reset-button">
                Reset
              </button>
            </div>
          </div>
          <section className="AdminAccounts-board">
            <div className="AdminAccounts-center-board">
              <div className="AdminAccounts-table-head">
                <p>Staff</p>
                <p>Date</p>
                <p>Category</p>
                <p>Description</p>
                <p>Amount</p>
              </div>
              <div className="AdminAccounts-table-body">
                {isFetchingPayments || isFetchingStaffs ? (
                  <span style={{ color: "black" }}>loading...</span>
                ) : filteredItems?.length > 0 ? (
                  filteredItems.map((item, index) => {
                    const staffMember = staff?.find(
                      (s) => s.staffUniqueId === item.collectedBy
                    );

                    const formattedDate = formatDistanceToNow(
                      new Date(item.createdAt),
                      { addSuffix: true }
                    );

                    return (
                      <AdminExpenseList
                        key={index}
                        name={staffMember?.fullName || "Unknown"}
                        date={formattedDate}
                        category={item.category}
                        description={item.description}
                        amount={item.amount}
                        index={index}
                      />
                    );
                  })
                ) : (
                  <span style={{ color: "black" }}>No records found</span>
                )}
              </div>
              <div className="ExpenseAccounts-totals">
                <h5>TOTAL</h5>
                <h5>{parseFloat(totalAmount)}</h5>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Expenses;

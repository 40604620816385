import React from "react";
import "./StaffList.css";
// import ReceptioistManageProfile from "../../ReceptioistManageProfile/ReceptioistManageProfile";
import { useState } from "react";
import { useGetStaffsQuery } from "../../../hooks/staff";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminProfile from "../AdminProfile/AdminProfile";
import StaffListItem from "./StaffListItem/StaffListItem";

const StaffList = () => {
  const { data: staffListItems, isPending } = useGetStaffsQuery();

  const [adminProfileShow, setAdminProfileShow] = useState(false);
  return (
    <div className="StaffList">
      <div className="StaffList-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="StaffList-space">
        <div className="StaffList-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="StaffList-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="StaffList-space-body">
          <div className="StaffList-title-board">
            <h2>Staff List</h2>
            <input type="text" placeholder="Search Staff" />
          </div>
          <section className="StaffList-board">
            <div className="StaffList-center-board">
              <div className="StaffList-table-head">
                <p>Name</p>
                <p>Unique No.</p>
                <p>Position</p>
              </div>
              <div className="StaffList-table-body">
                {isPending ? (
                  <div style={{ color: "black" }}>Loading...</div>
                ) : (
                  staffListItems?.map((items, index) => (
                    <StaffListItem
                      name={items.fullName}
                      id={items.staffUniqueId}
                      position={items.position}
                      idx={items._id}
                      index={index}
                      key={index}
                    />
                  ))
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StaffList;

import React, { useState } from "react";
import minc_card from "../../../assets/Nimc_profile.jpg";
import { useGetStaffDetailQuery } from "../../../hooks/staff";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminProfile from "../AdminProfile/AdminProfile";
import "./StaffInformation.css";

import { useSearchParams } from "react-router-dom";

const StaffInformation = () => {
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // Get the `id` from the query string

  const { data: staffInfo, isError } = useGetStaffDetailQuery(id);

  console.log(staffInfo);

  if (isError) {
    return <div>Error fetching staff details. Please try again later.</div>;
  }
  return (
    <div className="StaffInformation">
      <div className="StaffInformation-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="StaffInformation-space">
        <div className="StaffInformation-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="StaffInformation-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="StaffInformation-space-body">
          <section className="StaffInformation-board">
            <div className="StaffInformation-center-board">
              <div className="StaffInformation-center-cont ContLeft">
                <div>
                  <h2>{staffInfo?.fullName}</h2>
                  <p>{staffInfo?.phoneNumber}</p>
                </div>
                <div className="StaffInformation-info-section">
                  <div>
                    <div className="StaffInformation-mobile-salary">
                      <h2>Salary</h2>
                      <p>N{staffInfo?.salary}</p>
                    </div>
                  </div>
                  <h2>Staff ID: {staffInfo?.staffUniqueId}</h2>
                  <div className="StaffInformation-information">
                    <p>
                      <b>Address:</b>
                      {staffInfo?.address}
                    </p>
                    <p>
                      <b>Email:</b>
                      {staffInfo?.email}
                    </p>
                    <p>
                      <b>DOB:</b>
                      {staffInfo?.dateOfBirth}
                    </p>
                    <p>
                      <b>Gender:</b>
                      {staffInfo?.gender}
                    </p>
                    <p>
                      <b>Qualification:</b>
                      {staffInfo?.qualification}
                    </p>
                    <p>
                      <b>Position:</b>
                      {staffInfo?.position}
                    </p>
                  </div>
                </div>
                <div className="StaffInformation-cont-btn">
                  <button>Edit Salary</button>
                  <button>Stop Duties</button>
                </div>
                <div className="StaffInformation-mobile-nimc">
                  <img src={minc_card} alt="" />
                </div>
              </div>
              <div className="StaffInformation-center-cont ContRight">
                <div className="StaffInformation-contRight-up">
                  <div className="">
                    <h2>Salary</h2>
                    <p>{"N56,000"}</p>
                  </div>
                  <img src={staffInfo?.photo} alt="" />
                </div>
                <div className="StaffInformation-contRight-down">
                  <img src={staffInfo?.idPhoto} alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StaffInformation;

/** @format */

import React, { useContext, useState } from "react";
import "./ManageGuest.css";
import { Link } from "react-router-dom";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import GuestInfoTableRow from "../GuestInfoTableRow/GuestInfoTableRow";
import { useGetGuestsQuery } from "../../../hooks/guest";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import { Store } from "../../../Store";
import CashierProfile from "../../Cashier/CashierProfile/CashierProfile";
import AdminProfile from "../../Master-Admin/AdminProfile/AdminProfile";

const ManageGuest = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  const { data: GuestRoomDetails = [], isPending } = useGetGuestsQuery();

  const {
    state: { userInfo },
  } = useContext(Store);

  return (
    <>
      <div className="ManageGuest">
        <div className="ManageGuest-web">
          <div className="ManageGuest-guest-section">
            {userInfo && userInfo?.position === "Receptionist" ? (
              <ReceptionistDashboard receptionProfileShow={true} />
            ) : userInfo?.position === "Admin" ? (
              <AdminProfile adminProfileShow={true} />
            ) : (
              <CashierProfile cashProfileShow={true} />
            )}
          </div>

          <div className="ManageGuest-content-section">
            <div className="ManageGuest-mobile-header">
              <ReceptionistDashboard
                receptionProfileShow={receptionProfileShow}
                setReceptionProfileShow={setReceptionProfileShow}
              />
              <ReceptionHeader
                setReceptionProfileShow={setReceptionProfileShow}
              />
            </div>
            <div className="ManageGuest-content-head">
              <Link>Back to Homepage</Link>
            </div>
            <div className="ManageGuest-content-body">
              <div className="ManageGuest-bar-holder">
                <p>Guest Information</p>

                {isPending && (
                  <span style={{ color: "black" }}>loading...</span>
                )}
                <input type="text" placeholder="Search Guests" />
              </div>
              <div className="ManageGuest-content-holder">
                <div className="ManageGuest-table-section">
                  <div className="mg-web-table-holder">
                    <table className="mg-web-table">
                      <div className="ManageGuest-thead">
                        <p>Name</p>
                        <p>Unique No.</p>
                        <p> Contact</p>
                      </div>
                      <div className="ManageGuest-scroll ">
                        {GuestRoomDetails.map((details, index) => (
                          <GuestInfoTableRow
                            names={details.fullName}
                            uniqueNo={details.userUniqueId}
                            phone={details.phoneNumber}
                            id={details._id}
                            index={index}
                            key={index}
                          />
                        ))}
                      </div>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageGuest;

import React from "react";
import "./StaffListItem.css";
import copy from "../../../../assets/Copy.svg";
import { Link } from "react-router-dom";

const StaffListItem = ({ name, id, position, idx }) => {
  const [copySuccess, setCopySuccess] = React.useState("");
  const handleCopyClick = async () => {
    try {
      // Use the Clipboard API to copy text to the clipboard
      await navigator.clipboard.writeText(id);
      setCopySuccess("Copied!");

      // Optionally, clear the success message after a short delay
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
      setCopySuccess("Failed to copy");
    }
  };

  return (
    <div className="StaffList-table-row">
      <Link to={`/StaffInformation?id=${idx}`} className="StaffListItem-link">
        {name}
      </Link>
      <div className="StaffListItem-id">
        <p>{id}</p>{" "}
        <span style={{ display: "flex" }}>
          <button className="copy-btn" onClick={handleCopyClick}>
            <img src={copy} alt="" />
          </button>
          {copySuccess && (
            <span
              style={{ color: "#1f1515", marginLeft: "10px" }}
              className="copy-success"
            >
              {copySuccess}
            </span>
          )}
        </span>
      </div>
      <p className="StaffListItem-position">{position}</p>
    </div>
  );
};

export default StaffListItem;
